import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import CarouselMiniatures from "../carousel/carousel-with-miniatures";
import { navigate, useStaticQuery, graphql } from "gatsby";
import { Button } from "gatsby-theme-material-ui";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { formatCurrencyString } from "use-shopping-cart";
import { useShoppingCart } from "use-shopping-cart";

import BenefitsSectionMicroREC from "./benefits";
import "../../../static/microrec.css";
import MicrorecLowerpart from "./microrec_lowerpart";

const ProductSectionOptiREC = ({ takeanepStripe, connect, ...props }) => {
  const { addItem } = useShoppingCart();
  const [open, setOpen] = React.useState(false);

  const data = useStaticQuery(graphql`
    query {
      carrouselImagesBluetooth: allFile(
        filter: { relativePath: { regex: "/ecommerce/takeanep/" } }
        sort: { fields: relativePath }
      ) {
        nodes {
          childrenImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);

  const carrouselImagesBluetooth = data.carrouselImagesBluetooth;

  const handleClick = () => {
    handleAddItem();
    handleBackDrop();
    setTimeout(() => {
      link2UpsellPage();
    }, 400);
  };

  const handleAddItem = () => {
    addItem(takeanepStripe);
  };

  const handleBackDrop = () => {
    setOpen(!open);
  };

  const link2UpsellPage = () => {
    navigate("/store/takeanep/software", {});
  };

  return (
    <section
      id="main_MicroREC_section"
      style={{ marginTop: 110, marginBottom: 0 }}
    >
      <Container maxWidth={"lg"}>
        <Grid
          container
          spacing={3}
          justifyContent={"center"}
          marginTop={{ xs: 2 }}
        >
          <Grid item xs={11} sm={5}>
            <div
              style={{
                marginBottom: "1em",
                fontSize: "18px",
              }}
            >
              <a
                href="/store"
                className="links_post"
                style={{ color: "black", fontWeight: "500" }}
              >
                Catalog
              </a>{" "}
              <b>&gt;</b>{" "}
              <a href="" className="links_post">
                Premium Elbow Support
              </a>
            </div>
            <CarouselMiniatures data={carrouselImagesBluetooth.nodes} />
            <BenefitsSectionMicroREC />
          </Grid>
          <Grid
            container
            item
            xs={11}
            sm={6}
            marginTop={{ xs: 0, sm: 0 }}
            marginBottom={{ xs: 2, sm: 0 }}
            spacing={1}
            justifyContent={"space-between"}
            sx={{ alignContent: "flex-start" }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align={"left"}
                gutterBottom={true}
                sx={{ fontWeight: "bold", marginTop: "1em" }}
              >
                {takeanepStripe.displayName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom={true}>
                <b style={{ fontWeight: "400", fontSize: "16px" }}>from</b>{" "}
                <b>
                  {formatCurrencyString({
                    value: takeanepStripe.price,
                    currency: takeanepStripe.currency,
                  })}{" "}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                paragraph={true}
                gutterBottom={true}
                sx={{
                  textAlign: "justify",
                  fontSize: "18px",
                  marginBottom: "10px",
                }}
              >
                NEP is an innovative elbow support designed for ophthalmologists
                working at slit lamps or ALK lasers. Your elbow rests softly and
                stably on the NEP, which is the first of its kind equipped with
                a soft cushion for padding.
              </Typography>
              <Typography
                variant="body1"
                paragraph={true}
                gutterBottom={true}
                sx={{
                  textAlign: "justify",
                  fontSize: "18px",
                  marginBottom: "10px",
                }}
              >
                The height can be individually adjusted with additional discs,
                allowing for optimal customization.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <hr></hr>
            </Grid>

            <Grid
              item
              xs={12}
              justifyContent={"center"}
              sx={{ textAlign: "center" }}
            >
              <Button
                onClick={handleClick}
                color="primary"
                variant="contained"
                endIcon={<AddShoppingCartOutlinedIcon />}
                sx={{
                  padding: "8px 30px",
                  marginTop: "1em",
                  borderRadius: "10px",
                }}
              >
                Add to Cart
              </Button>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </Grid>
          <MicrorecLowerpart />
        </Grid>
      </Container>
    </section>
  );
};

export default ProductSectionOptiREC;
